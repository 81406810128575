import { CcDefaultConfig } from "./shared";

// Models
export interface CcJobUrl {
  redirect: string;
  jobOffer: string;
}

export interface CcLabel {
  value: string,
}

export interface CcLabels {
  contractTypeList: Array<CcLabel>,
}

interface CcSimpleType {
  label: string,
}

export interface CcJobOffer {
  id: number,
  title: string,
  url: CcJobUrl,
  lastModification: Date,
  jobCategories: Array<CcSimpleType>,
  publicationDate: Date,
  labels: CcLabels,
  externalUrl: string,
  locality: string,
}

export interface CcJobSearchResponse {
  total: number;
  content: Array<CcJobOffer>;
}

export interface CcStorageItem {
  value,
  expiry: number,
}

// Config interfaces
export interface CcOffersFiltersConfig {
  jobCategories?: Array<number>,
  jobs?: Array<number>,
  units?: Array<number>,
  contracts?: Array<string>,
  industries?: Array<number>,
  locations?: Array<number>,
  limitLocations?: boolean,
  useFacets?: boolean,
}

export interface CcOffersConfig {
  count?: number,
  title?: string,
  offerButton?: string,
  noresult?: string,
  targetBlank?: boolean,
  show?: CcOffersShowConfig,
  classes?: CcOffersClassesConfig,
}

export interface CcOffersShowConfig {
  title?: boolean,
  locality?: boolean ,
  contracts?: boolean,
  noresult?: boolean,
  jobCategories?: boolean,
  link?: boolean,
}

export interface CcOffersClassesConfig {
  title?: string,
  block?: string,
  offer?: string,
  subLine?: string,
  noresult?: string,
  link?: string,
}

export interface CcOffersCounterConfig extends CcOffersFiltersConfig, CcDefaultConfig {
  thousandsSeparator: string;
}

export interface CcLastOffersConfig extends CcOffersConfig, CcDefaultConfig {
  filters: CcOffersFiltersConfig,
}

export interface CcContextualOffersConfig extends CcOffersConfig, CcDefaultConfig {}

export const ccOffersFiltersConfig = {
  jobCategories: [],
  jobs: [],
  industries: [],
  units: [],
  contracts: [],
  locations: [],
  limitLocations: false,
  useFacets: false,
} as CcOffersFiltersConfig;

export const ccOffersDefaultShowConfig = {
  title: false,
  locality: true,
  contracts: true,
  jobCategories: false,
  noresult: false,
  link: true,
} as CcOffersShowConfig;


export const ccOffersDefaultClassesConfig = {
  title: '',
  block: '',
  offer: '',
  subLine: '',
  link: '',
  noresult: '',
} as CcOffersClassesConfig;

export const ccOffersDefaultConfig = {
  title: 'Dernières offres',
  noresult: 'Aucune offre',
  offerButton: "Voir l'offre",
  count: 3,
  targetBlank: false,
  show: ccOffersDefaultShowConfig,
  classes: ccOffersDefaultClassesConfig,
} as CcOffersConfig;

export function displayOffers(offers: CcJobSearchResponse, config: CcLastOffersConfig | CcOffersConfig): string {
  const offerList = offers.content ? offers.content.slice(0, 5).map(c => displayOffer(c, config)).join('') : '';
  if (offers.total === 0 && config.show.noresult) {
    return `<p class="${config.classes?.noresult}">${config.noresult}</p>`;
  } else {
    return `${config.show.title && config.title ? `<h3 class="${config.classes.title}">${config.title}</h3>` : ''}
    <ul class="list-unstyled">${offerList}</ul>`;
  }
}

export function displayOffer(offer: CcJobOffer, config: CcOffersConfig | CcLastOffersConfig): string {
  const attributes = config?.targetBlank ? ' target="_blank"' : '';
  const subLines = new Array<string>();
  if (config?.show) {
    config.show.locality && (subLines.push(offer.locality));
    config.show.contracts && (subLines.push(offer.labels.contractTypeList.map((contract: CcLabel) => contract.value).join(', ')));
    config.show.jobCategories && (subLines.push(offer.jobCategories.map((cat: CcSimpleType) => cat.label).join(', ')));
  }
  return `<li class="cc-block ${config.classes.block ? config.classes.block : 'p-3'}">
    <h4 class="mb-0 ${config.classes.title ? config.classes.title : ''}">${offer.title}</h4>
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <p class="${config.classes.subLine ? config.classes.subLine : 'mt-2 mb-0'}">${subLines.join(' | ')}</p>
      </div>
      ${config.show.link ? `<div class="col-md-4 col-sm-12 text-right">
        <a class="btn btn-primary ${config.classes.link ? config.classes.link : ''}"${config.targetBlank ? ' target="_blank"' : ''} href="${config.domain}${offer.url.jobOffer}"${attributes}>${config.offerButton}</a>
      </div>` : ''}
    </div>
  </li>`;
}
