import fr from './fr.json';
import en from './en.json';
import de from './de.json';

const LANGUAGES = { fr, en, de };

export function getDefaultLang(): string {
    const docLang = document.documentElement.lang;
    return docLang?.length ? docLang : 'fr';
}

export function getMessage(language: string, key: string): string {
    const dict = LANGUAGES[language?.split('-')[0] || 'fr'] || fr;
    const keys = key.split('.');
    let text = dict;
    keys.map(k => {
        text = text[k];
    });
    return typeof(text) === 'string' ? text : key;
}
