import { domReady, queue } from ".";
import { CcContextualOffersConfig, CcJobSearchResponse, ccOffersDefaultConfig, CcStorageItem, displayOffers } from "../offers";

const MAX = {
  offers: 20,
  contracts: 20,
  jobs: 20,
  locations: 20,
};

function isLocalStorageEnabled() {
  try {
    const testKey = 'test';
    window.localStorage.setItem(testKey, '1');
    window.localStorage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
}

function getLocalStorageValue(key: string) {
  const itemStr = window.localStorage.getItem(key);
  if (itemStr) {
    try {
      const item = JSON.parse(itemStr);
      if (item.value) {
        // Assume its an storage item
        const storageItem = item as CcStorageItem;
        return storageItem.value;
      }
      return item;
    } catch (error) {
      // If not a JSON object return it as string
      return itemStr;
    }
  }
  return null;
}

export const ccContextualOffersDefaultConfig = { ...ccOffersDefaultConfig, domain: '' } as CcContextualOffersConfig;

export function contextualOffers(container: string|HTMLElement, config: CcContextualOffersConfig = ccContextualOffersDefaultConfig): void {
  config = {
    ...ccContextualOffersDefaultConfig,
    count: config.count ? config.count : ccContextualOffersDefaultConfig.count,
    targetBlank: config.targetBlank ? config.targetBlank : ccContextualOffersDefaultConfig.targetBlank,
    title: config.title ? config.title : ccContextualOffersDefaultConfig.title,
    offerButton: config.offerButton ? config.offerButton : ccContextualOffersDefaultConfig.offerButton,
    classes: { ...ccContextualOffersDefaultConfig.classes, ...config.classes},
    show: { ...ccContextualOffersDefaultConfig.show, ...config.show},
    domain: config.domain ? config.domain : ccContextualOffersDefaultConfig.domain,
  };

  domReady(function() {
    queue.enqueue(async () => {
      if (container && isLocalStorageEnabled()) {
        const viewedOffers = getLocalStorageValue('viewedOffers');
        if (typeof viewedOffers === 'object' && viewedOffers?.length) {
          const body = {
            jobs: [{ id: "-1" }],
            contracts: [],
            locations: [],
            significantOffers: [],
          };
          const viewedJobs = getLocalStorageValue('viewedJobs');
          if (typeof viewedJobs === 'object' && viewedJobs?.length) {
            body.jobs =  viewedJobs.slice(0, MAX.jobs).map(j => ({id: j.toString(), levels: []}));
          }
          const viewedContracts = getLocalStorageValue('viewedContracts');
          if (typeof viewedContracts === 'object' && viewedContracts?.length) {
            body.contracts = viewedContracts.slice(0, MAX.contracts);
          }
          const viewedLocations = getLocalStorageValue('viewedLocations');
          if (typeof viewedLocations === 'object' && viewedLocations?.length) {
            body.locations = viewedLocations.slice(0, MAX.locations).map(u => ({id: u}));
          }
          body.significantOffers = viewedOffers.slice(0, MAX.offers);


          const element = typeof container === 'string' ? document.getElementById(container) : container;
          if (element) {
            const response = await fetch(`${config.domain}/api/joboffers/search/matching?size=${config.count}&sorting=score&minimalThreshold=0&scoringVersion=SERJOBSEARCH`, {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "Content-type": "application/json",
              },
              credentials: 'include',
            });
            if (!response.ok) {
              throw new Error('Cannot load contextual offers.');
            }
            const jobSearchResponse: CcJobSearchResponse = await response.json();
            let total = 0;
            if (jobSearchResponse.total) {
              total = jobSearchResponse.total;
              element.innerHTML = displayOffers(jobSearchResponse, config);
            }
            element.setAttribute('data-total', total.toString());
            return total;
          }
          return 0;
        }
      }
    });
  });
}
