import { CcDefaultConfig } from './shared';

// Config interfaces
export interface CcCandidatesCounterConfig extends CcCandidatesFiltersConfig, CcDefaultConfig {
  thousandsSeparator: string;
}

export interface CcCandidatesFiltersConfig {
  jobs?: Array<number>,
  locations?: Array<number>,
  contracts?: Array<string>,
  visibleCvtheque?: boolean,
}

export const ccCandidatesFiltersConfig = {
  jobs: [],
  locations: [],
  contracts: [],
  visibleCvtheque: null,
} as CcCandidatesFiltersConfig;

export const ccCandidatesCounterDefaultConfig = {
  ...ccCandidatesFiltersConfig,
  domain: '',
  thousandsSeparator: ''
} as CcCandidatesCounterConfig;
