import { CcDefaultConfig } from './../shared';
import { domReady, queue } from ".";

export interface CcApplicationConfig extends CcDefaultConfig {
  externalRef: string,
  companyId: number,
  familiar: boolean,
  successMessage: string,
  button: {
    color: string,
    bgColor: string,
  },
  block: {
    color: string,
    font: string,
  },
  cgu: {
    text: string,
    link: string,
    textLink: string,
    colorLink: string,
  },
  resume: {
    maxSize: number;
    formats: string;
  }
};

export const ccApplicationDefaultConfig = {
  externalRef: '',
  domain: '',
  familiar: false,
  successMessage: "Merci d'avoir postulé",
  button: {
    color: '',
    bgColor: '',
  },
  block: {
    color: '#000',
    font: '',
  },
  cgu: {
    text: 'En postulant je reconnais avoir pris connaissance et accepter les {link}',
    link: '#',
    textLink: "mentions légales et conditions générales d'utilisation",
    colorLink: '',
  },
  resume: {
    maxSize: 0,
    formats: '',
  }
} as CcApplicationConfig;

function display(element: HTMLElement, config: CcApplicationConfig): string {
  const color = (color: string) => `color:${color}`;
  const bgColor = (bgColor: string) => `background-color:${bgColor}`;
  const font = (font: string) => `font-family:${font}`;
  const blockStyles = [];
  config.block.color && blockStyles.push(color(config.block.color));
  config.block.font && blockStyles.push(font(config.block.font));
  const buttonStyles = [];
  config.button.color && buttonStyles.push(color(config.button.color));
  config.button.bgColor && buttonStyles.push(bgColor(config.button.bgColor));
  const cgu = `<label for="cgu" style="margin-left:10px; display:inline-block; font-weight: 400; width: calc(100% - 30px); vertical-align: top;">${displayCGU(config)}</label>`;
  const prefix = config.familiar ? 'Ton' : 'Votre';
  const resumeMaxSize = config.resume.maxSize > 0 ? `̀${config.resume.maxSize} Mo maximum` : '';
  let resumeFormats = config.resume.formats ? `̀Formats acceptés : ${config.resume.formats}` : '';
  if (resumeMaxSize) {
    resumeFormats = resumeFormats + ' / ';
  }
  return `
    <style>
        @media screen and (max-width: 980px) {
          form.cc-upload-form > div > div {
            width: 100% !important;
            margin-right: 0 !important;
            margin-left: 0 !important;
            margin-bottom: 10px !important;
          }
        }
    </style>
    <form id="${element}-form" method="POST" enctype="multipart/form-data" action="" class="cc-upload-form" style="border-radius: 1rem;${blockStyles.join(';')}">
      <div style="margin-bottom: 10px;">
        <div style="display:inline-block; width: calc(50% - 10px); margin-right: 10px; vertical-align: top;">
          <label for="firstName">${prefix} prénom : *</label>
          <input id="firstName" style="width: 100%; padding: 0.3rem 1rem; border: 1px solid #babebf; border-radius: 0.3rem;" type="text" name="firstName" />
        </div><div style="display:inline-block;  width: calc(50% - 10px);  margin-left: 10px; vertical-align: top;">
          <label for="name">${prefix} nom : *</label>
          <input id="name" style="width: 100%; padding: 0.3rem 1rem; border: 1px solid #babebf; border-radius: 0.3rem;" type="text" name="name" />
        </div>
      </div>
      <div style="margin-bottom: 10px;">
        <div style="display:inline-block;  width: calc(50% - 10px); margin-right: 10px; vertical-align: top;">
          <label for="mail">${prefix} adresse e-mail : *</label>
          <input id="mail" style="width: 100%; padding: 0.3rem 1rem; border: 1px solid #babebf; border-radius: 0.3rem;" type="mail" name="mail" />
        </div><div style="display:inline-block; width: calc(50% - 10px); margin-left: 10px; vertical-align: top;">
          <label for="phone">${prefix} numéro de téléphone : *</label>
          <input id="phone" style="width: 100%; padding: 0.3rem 1rem; border: 1px solid #babebf; border-radius: 0.3rem;" type="phone" name="phone" />
        </div>
      </div>
      <div style="margin-bottom: 20px; vertical-align: top;">
        <label>${prefix} CV : *</label>
        <label for="resume" type="button" class="upload-btn" style="border-style: none; cursor: pointer; padding: 0.3rem 1rem; text-align: left; border: 1px solid #babebf; border-radius: 0.3rem; background-color: #fff; display: block; width: 100%; line-height: 21px;">
          <svg enable-background="new 0 0 24 24" height="19px" viewBox="0 0 24 24" width="19px" fill="${config.block.color}">
            <g>
              <rect fill="none" height="19" width="19"/>
            </g>
            <g>
              <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M7,9l1.41,1.41L11,7.83V16h2V7.83l2.59,2.58L17,9l-5-5L7,9z"/>
            </g>
          </svg>
          <span class="label-resume" style="margin-left:5px">Ajouter un cv</span>
        </label>
        <small class="conditions-resume" style="margin-top: 5px">${resumeFormats} ${resumeMaxSize}</small>
        <input id="resume" class="upload-input" type="file" name="resume" style="position: absolute;margin-left: -9999px;">
      </div>
      <div style="margin-bottom: 20px; vertical-align: top;">
        <input id="cgu" type="checkbox" name="cgu" />
        ${cgu}
      </div>
      <button class="submit-btn" type="button" style="margin: 0px auto; display: block; border-style: none; cursor: pointer; padding: 0.5rem 2rem; width: 100%; ${buttonStyles.join(';')}">Je postule</button>
      <small style="margin-top: 20px; display: block;">* Champs obligatoires</small>
    </form>
  `;
}

function displayCGU(config: CcApplicationConfig): string {
  const link = `<a href="${config.cgu.link}" style="color: ${config.cgu.colorLink}">${config.cgu.textLink}</a>`;
  return config.cgu.text.replace('{link}', link);
}

function validateEmail(email: string): boolean {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function validatePhoneNumber(phone: string): boolean {
  const re = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
  return re.test(phone);
}

export function applicationATS(container: string|HTMLElement, config: CcApplicationConfig = ccApplicationDefaultConfig): void {
  config = {
    ...ccApplicationDefaultConfig,
    externalRef: config.externalRef ? config.externalRef : ccApplicationDefaultConfig.externalRef,
    companyId: config.companyId ? config.companyId : ccApplicationDefaultConfig.companyId,
    domain: config.domain ? config.domain : ccApplicationDefaultConfig.domain,
    familiar: config.familiar ? config.familiar : ccApplicationDefaultConfig.familiar,
    successMessage: config.successMessage ? config.successMessage : ccApplicationDefaultConfig.successMessage,
    button: {...ccApplicationDefaultConfig.button, ...config.button},
    block: {...ccApplicationDefaultConfig.block, ...config.block},
    cgu: {...ccApplicationDefaultConfig.cgu, ...config.cgu},
    resume: {...ccApplicationDefaultConfig.resume, ...config.resume},
  };

  domReady(function() {
    queue.enqueue(async () => {
      if (container) {
        const element = typeof container === 'string' ? document.getElementById(container) : container;
        if (element) {
          element.innerHTML = display(element.id, config);

          const form = document.getElementById(`${element.id}-form`);
          let inputs = Array.from(form.getElementsByTagName('input'));
          let inputFile = null;
          let resumeFile = null;
          inputs.forEach(item => {
            if (item.className == 'upload-input') {
              inputFile = item;
            }
          });
          const buttonUpload = form.getElementsByClassName('upload-btn')[0];
          const buttonSubmit = form.getElementsByClassName('submit-btn')[0];

          inputFile.addEventListener('change', () => {
            const file = inputFile.files[0];
            const splits = file.name.split('.');
            const extension = splits[splits.length - 1];
            const formats = config.resume.formats ? config.resume.formats.replace(/\s/g, '').split(',') : null;
            if(buttonUpload.parentElement.getElementsByTagName('p').length) {
              buttonUpload.setAttribute("style", buttonUpload.getAttribute('style') + "border: 1px solid #babebf");
              buttonUpload.parentElement.removeChild(buttonUpload.parentElement.getElementsByTagName('p')[0]);
            }
            if (formats && !formats.find(e => e.toLowerCase() === extension.toLowerCase())) {
              buttonUpload.setAttribute("style", buttonUpload.getAttribute('style') + "border: 1px solid red");
              const error = document.createElement('p');
              error.style.color = 'red';
              error.style.fontSize = '80%';
              error.innerText = `Veuillez déposer un CV dans l'un des formats suivants : ${config.resume.formats}`;
              buttonUpload.parentElement.appendChild(error);
            } else if ((!formats || formats.find(e => e.toLowerCase() === extension.toLowerCase())) && (config.resume.maxSize > 0 && file.size > config.resume.maxSize * 1000000)) {
              buttonUpload.setAttribute("style", buttonUpload.getAttribute('style') + "border: 1px solid red");
              const error = document.createElement('p');
              error.style.color = 'red';
              error.style.fontSize = '80%';
              error.innerText = `La taille de votre document ne doit pas excéder ${config.resume.maxSize}Mo.`;
              buttonUpload.parentElement.appendChild(error);
            } else {
              resumeFile = file;
              form.getElementsByClassName('label-resume')[0].innerHTML = resumeFile.name;
            }
          });
          buttonSubmit.addEventListener('click', () => {
            let firstName = null;
            let name = null;
            let mail = null;
            let phone = null;
            let cgu = false;
            inputs.forEach(item => {
              switch(item.name) {
                case 'firstName':
                  firstName = item.value;
                  if (!firstName && !item.parentElement.getElementsByTagName('small').length) {
                    item.style.border = '1px solid red';
                    const error = document.createElement('small');
                    error.style.color = 'red';
                    error.innerText = 'Le prénom est obligatoire';
                    item.parentElement.appendChild(error);
                  } else if (firstName && item.parentElement.getElementsByTagName('small').length) {
                    item.style.border = '1px solid #babebf';
                    item.parentElement.removeChild(item.parentElement.getElementsByTagName('small')[0]);
                  }
                  break;
                case 'name':
                  name = item.value;
                  if (!name && !item.parentElement.getElementsByTagName('small').length) {
                    item.style.border = '1px solid red';
                    const error = document.createElement('small');
                    error.style.color = 'red';
                    error.innerText = 'Le nom est obligatoire';
                    item.parentElement.appendChild(error);
                  } else if (name && item.parentElement.getElementsByTagName('small').length) {
                    item.style.border = '1px solid #babebf';
                    item.parentElement.removeChild(item.parentElement.getElementsByTagName('small')[0]);
                  }
                  break;
                case 'mail':
                  mail = item.value;
                  if (!mail && !item.parentElement.getElementsByTagName('small').length) {
                    item.style.border = '1px solid red';
                    const error = document.createElement('small');
                    error.style.color = 'red';
                    error.innerText = `L'e-mail est obligatoire`;
                    item.parentElement.appendChild(error);
                  } else if (mail && !validateEmail(mail)) {
                    if(item.parentElement.getElementsByTagName('small').length) {
                      item.parentElement.removeChild(item.parentElement.getElementsByTagName('small')[0]);
                    }
                    item.style.border = '1px solid red';
                    const error = document.createElement('small');
                    error.style.color = 'red';
                    error.innerText = `L'email est au mauvais format`;
                    item.parentElement.appendChild(error);
                  } else if (mail && item.parentElement.getElementsByTagName('small').length) {
                    item.style.border = '1px solid #babebf';
                    item.parentElement.removeChild(item.parentElement.getElementsByTagName('small')[0]);
                  }
                  break;
                case 'phone':
                  phone = item.value;
                  if (!phone && !item.parentElement.getElementsByTagName('small').length) {
                    item.style.border = '1px solid red';
                    const error = document.createElement('small');
                    error.style.color = 'red';
                    error.innerText = 'Le téléphone est obligatoire';
                    item.parentElement.appendChild(error);
                  } else if (phone && !validatePhoneNumber(phone)) {
                    if(item.parentElement.getElementsByTagName('small').length) {
                      item.parentElement.removeChild(item.parentElement.getElementsByTagName('small')[0]);
                    }
                    item.style.border = '1px solid red';
                    const error = document.createElement('small');
                    error.style.color = 'red';
                    error.innerText = `Le téléphone est au mauvais format`;
                    item.parentElement.appendChild(error);
                  } else if (phone && item.parentElement.getElementsByTagName('small').length) {
                    item.style.border = '1px solid #babebf';
                    item.parentElement.removeChild(item.parentElement.getElementsByTagName('small')[0]);
                  }
                  break;
                case 'cgu':
                  cgu = item.checked;
                  if (!item.checked && !item.parentElement.getElementsByTagName('small').length) {
                    const error = document.createElement('small');
                    error.style.color = 'red';
                    error.style.display = 'block';
                    error.innerText = 'La validation des cgu est obligatoire';
                    item.parentElement.appendChild(error);
                  } else if (item.checked && item.parentElement.getElementsByTagName('small').length) {
                    item.style.border = '1px solid #babebf';
                    item.parentElement.removeChild(item.parentElement.getElementsByTagName('small')[0]);
                  }
                  break;
              }
            });
            if (resumeFile == null && !buttonUpload.parentElement.getElementsByTagName('p').length) {
              buttonUpload.setAttribute("style", buttonUpload.getAttribute('style') + "border: 1px solid red");
              const error = document.createElement('p');
              error.style.color = 'red';
              error.style.fontSize = '80%';
              error.innerText = 'Le CV obligatoire';
              buttonUpload.parentElement.appendChild(error);
            } else if (resumeFile && buttonUpload.parentElement.getElementsByTagName('p').length) {
              buttonUpload.setAttribute("style", buttonUpload.getAttribute('style') + "border: 1px solid #babebf");
              buttonUpload.parentElement.removeChild(buttonUpload.parentElement.getElementsByTagName('p')[0]);
            }

            if (firstName && name && mail && phone && resumeFile && cgu && config.companyId && config.externalRef) {
              let reader = new FileReader();
              reader.readAsDataURL(resumeFile);
              reader.onload = async function () {
                const resultFile = reader.result.toString();
                const body = {
                  companyId: config.companyId,
                  email: mail,
                  externalRef: config.externalRef,
                  resume: {
                    firstName: firstName,
                    name: name,
                    phoneNumber: phone,
                  },
                  resumeFile: {
                    data: resultFile.split(';base64,')[1],
                    name: resumeFile.name
                  }
                }
                const response = await fetch(`${config.domain}/api/ats/application?context=CAREER1_ATS`, {
                  method: "POST",
                  body: JSON.stringify(body),
                  headers: {
                    "Content-type": "application/json",
                  },
                  credentials: 'include',
                });
                if (!response.ok) {
                  const error = document.createElement('div');
                  error.style.color = '#721c24';
                  error.style.backgroundColor = '#f8d7da';
                  error.style.borderColor = '#f5c6cb';
                  error.style.margin = '0.5rem 0';
                  error.style.padding = '0.75rem 1.25rem';
                  const span = document.createElement('span');
                  span.innerText = 'Une erreur innatendu est survenue';
                  error.appendChild(span);
                  form.appendChild(error);
                }
                if (response.ok) {
                  const success = document.createElement('p');
                  success.innerText = config.successMessage;
                  success.style.fontWeight = 'bold';
                  success.style.marginTop = '10px';
                  const successDiv = document.createElement('div');
                  successDiv.setAttribute("style", "margin: 0 auto; display: block; padding: 0.2rem 2rem; font-weight: bold; font-size: 20px; width: 100%; text-align: center; background-color: #28a745; color: #fff");
                  successDiv.innerHTML = '&#10003';
                  buttonSubmit.parentElement.replaceChild(successDiv, buttonSubmit);
                  form.appendChild(success);
                }
              };
            }
          });
        }
      }
    })
  });
}
