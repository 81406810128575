import Queue from "queue-promise";

export enum WIDGETS {
  lastOffers = 'lastOffers',
  contextualOffers = 'contextualOffers',
  offersCounter = 'offersCounter',
  uploadResume = 'uploadResume',
  applicationATS = 'applicationATS',
  searchOffers = 'searchOffers',
  candidatesCounter = 'candidatesCounter',
};
export const GNS = 'Cc';
export const NS = 'bridget';
export function domReady(callback) {
  document.readyState === "interactive" || document.readyState === "complete" ? callback() : document.addEventListener("DOMContentLoaded", callback);
};
export const queue = new Queue({
  concurrent: 2,
  interval: 500,
});
