import { domReady, queue } from ".";
import { CcCandidatesCounterConfig, ccCandidatesCounterDefaultConfig } from "../candidates";

export function candidatesCounter(container: string|HTMLElement, config: CcCandidatesCounterConfig = ccCandidatesCounterDefaultConfig): void {
  domReady(function() {
    queue.enqueue(async () => {
      if (container) {
        const criterias = new Array<string>();
        // construct criterias
        criterias.push('registered=true');
        config.jobs && (config.jobs.map(c => 'jobId=' + c).map(c => criterias.push(c)));
        config.locations && (config.locations.map(jc => 'locationGeonameId=' + jc).map(jc => criterias.push(jc)));
        config.contracts && (config.contracts.map(c => 'contractTypeMeteojobId=' + c).map(c => criterias.push(c)));
        config.visibleCvtheque === true && criterias.push('visibleByCompany=true');
        config.domain = config.domain ? config.domain : ccCandidatesCounterDefaultConfig.domain;
        config.thousandsSeparator = config.thousandsSeparator ? config.thousandsSeparator : ccCandidatesCounterDefaultConfig.thousandsSeparator;

        // fetch number of candidates related to criterias
        const response = await fetch(`${config.domain}/api/candidates/count?size=0${criterias.length ? '&' + criterias?.join('&') : ''}`, { credentials: 'include' });
        if (!response.ok) {
            throw new Error('');
        }
        const countCandidates = await response.json();
        const element = typeof container === 'string' ? document.getElementById(container) : container;
        if (element) {
          element.innerHTML = (config.thousandsSeparator?.length > 0) ? countCandidates.count.toLocaleString(config.thousandsSeparator) : `${countCandidates.count}`;
          return countCandidates.count;
        }
      }
    });
  });
};
