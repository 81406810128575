import { CcJobSearchResponse, CcOffersCounterConfig, ccOffersFiltersConfig } from "../offers";
import { domReady, queue } from './index';

export const ccOffersCounterDefaultConfig = { ...ccOffersFiltersConfig, domain: '', thousandsSeparator: '' } as CcOffersCounterConfig;

export function offersCounter(container: string|HTMLElement, config: CcOffersCounterConfig = ccOffersCounterDefaultConfig): void {
  domReady(function() {
    queue.enqueue(async () => {
      if (container) {
          const criterias = new Array<string>();
          // construct criterias
          config.jobCategories && (config.jobCategories.map(jc => 'jobCategory=' + jc).map(jc => criterias.push(jc)));
          config.jobs && (config.jobs.map(c => (config.useFacets == true ? 'facetJob=' : 'job=') + c).map(c => criterias.push(c)));
          config.industries && (config.industries.map(i => (config.useFacets == true ? 'facetIndustry=' : 'industry=') + i).map(i => criterias.push(i)));
          config.units && (config.units.map(u => 'facetOrganizationUnitId=' + u).map(u => criterias.push(u)));
          config.contracts && (config.contracts.map(c => (config.useFacets == true ? 'facetContract=' : 'contract=') + c).map(c => criterias.push(c)));
          config.locations && (config.locations.map(jc => 'location=' + jc).map(jc => criterias.push(jc)));
          config.limitLocations == true && criterias.push('expandLocations=' + !config.limitLocations);
          config.domain = config.domain ? config.domain : ccOffersCounterDefaultConfig.domain;
          config.thousandsSeparator = config.thousandsSeparator ? config.thousandsSeparator : ccOffersCounterDefaultConfig.thousandsSeparator;
          // fetch number of offers related to criterias
          const response = await fetch(`${config.domain}/api/joboffers/search?size=0${criterias.length ? '&' + criterias?.join('&') : ''}`, { credentials: 'include' });
          if (!response.ok) {
              throw new Error('Cannot load offers counter.');
          }
          const jobSearchResponse: CcJobSearchResponse = await response.json();
          const element = typeof container === 'string' ? document.getElementById(container) : container;
          element && (element.innerHTML = (config.thousandsSeparator?.length > 0) ? jobSearchResponse.total.toLocaleString(config.thousandsSeparator) : `${jobSearchResponse.total}`);
          return jobSearchResponse.total;
      }
    })
  });
};
