import { domReady, queue } from ".";
import { CcJobSearchResponse, displayOffers, ccOffersDefaultConfig, ccOffersFiltersConfig, ccOffersDefaultShowConfig, ccOffersDefaultClassesConfig, CcLastOffersConfig } from "../offers";

export const ccLastOffersDefaultConfig = { ...ccOffersDefaultConfig, filters: ccOffersFiltersConfig, domain: '' } as CcLastOffersConfig;

export function lastOffers(container: string|HTMLElement, config: CcLastOffersConfig = ccLastOffersDefaultConfig) {
  config = {
    ...ccLastOffersDefaultConfig,
    title: config.title ? config.title : ccLastOffersDefaultConfig.title,
    noresult: config.noresult ? config.noresult : ccLastOffersDefaultConfig.noresult,
    offerButton: config.offerButton ? config.offerButton : ccLastOffersDefaultConfig.offerButton,
    targetBlank: config.targetBlank ? config.targetBlank : ccLastOffersDefaultConfig.targetBlank,
    count: config.count ? config.count : ccLastOffersDefaultConfig.count,
    classes: {...ccOffersDefaultClassesConfig, ...config.classes},
    filters: {...ccOffersFiltersConfig, ...config.filters},
    show: { ...ccOffersDefaultShowConfig, ...config.show},
    domain: config.domain ? config.domain : ccLastOffersDefaultConfig.domain,
  } as CcLastOffersConfig;

  domReady(function() {
    queue.enqueue(async () => {
      const element = typeof container === 'string' ? document.getElementById(container) : container;
      if (element) {

        // construct criterias
        const criterias = new Array<string>();
        const pushCriteria = (key: string, value: string) => criterias.push(`${key}=${value}`);
        config.filters.jobCategories && (config.filters.jobCategories.map(pushCriteria.bind(this, 'jobCategory')));
        config.filters.jobs && (config.filters.jobs.map(pushCriteria.bind(this, 'job')));
        config.filters.units && (config.filters.units.map(pushCriteria.bind(this, 'facetOrganizationUnitId')));
        config.filters.contracts && (config.filters.contracts.map(pushCriteria.bind(this, 'contract')));
        config.filters.locations && (config.filters.locations.map(pushCriteria.bind(this, 'location')));
        config.filters.limitLocations == true && criterias.push('expandLocations=' + !config.filters.limitLocations);

        // fetch number of offers related to criterias
        const response = await fetch(`${config.domain}/api/joboffers/search?sorting=DATE&size=${config.count}${criterias.length ? '&' + criterias?.join('&') : ''}`, { credentials: 'include' });
        if (!response.ok) {
          throw new Error('Cannot load last offers.');
        }
        const jobSearchResponse: CcJobSearchResponse = await response.json();
        const total = jobSearchResponse.total;
        element.setAttribute('data-total', total.toString());
        element.innerHTML = (config.show.noresult && total === 0) || total > 0 ? displayOffers(jobSearchResponse, config) : '';
        return total;
      }
    });
  });
};
