import {GNS, NS, WIDGETS} from './widgets';
import {contextualOffers} from './widgets/contextualOffers';
import {lastOffers} from './widgets/lastOffers';
import {offersCounter} from './widgets/offersCounter';
import {uploadResume} from './widgets/upload';
import {applicationATS} from './widgets/applicationATS';
import {searchOffers} from './widgets/searchOffers';
import {candidatesCounter} from './widgets/candidatesCounter'

window[GNS] = window[GNS] || [];
window[GNS][NS] = window[GNS][NS] || [];
window[GNS][NS][WIDGETS.offersCounter] = offersCounter;
window[GNS][NS][WIDGETS.lastOffers] = lastOffers;
window[GNS][NS][WIDGETS.contextualOffers] = contextualOffers;
window[GNS][NS][WIDGETS.uploadResume] = uploadResume;
window[GNS][NS][WIDGETS.applicationATS] = applicationATS;
window[GNS][NS][WIDGETS.searchOffers] = searchOffers;
window[GNS][NS][WIDGETS.candidatesCounter] = candidatesCounter;
