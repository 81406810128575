import { domReady, queue } from ".";
import { CcDefaultConfig } from "../shared";

export interface CcUploadConfig extends CcDefaultConfig {
  displayIcon: boolean,
  newTab: boolean,
  block: {
    styled: boolean,
    width: number|string,
    bgColor: string,
    color: string,
    border: string,
  },
  button: {
    color: string,
    bgColor: string,
    borderColor: string,
    class: string,
    text: string,
  },
  title: {
    display: boolean,
    color: string,
    text: string,
  },
  separator: {
    display: boolean,
    color: string,
    text: string,
  },
  conditions: {
    display: boolean,
    color: string,
    text: string,
  },
  cgu: {
    display: boolean,
    color: string,
    text: string,
    link: string,
    textLink: string,
    colorLink: string,
  },
};

export const ccUploadDefaultConfig = {
  domain: '',
  displayIcon: true,
  newTab: false,
  block: {
    styled: true,
    width: 'auto',
    bgColor: 'rgb(234, 234, 234)',
    border: '2px dashed rgb(53, 53, 53)',
    color: '#000',
  },
  button: {
    color: 'rgb(255, 255, 255)',
    bgColor: 'rgb(174, 0, 255)',
    borderColor: 'rgb(174, 0, 255)',
    text: 'Sélectionnez un fichier',
  },
  title: {
    display: true,
    text: 'Glissez / déposez votre CV',
  },
  separator: {
    display: true,
    text: 'OU',
  },
  conditions: {
    display: true,
    text: 'Formats acceptés : odt, doc, docx, pdf, txt, rtf / 2Mo maximum ou png, jpg, jpeg / 10Mo maximum',
  },
  cgu: {
    display: true,
    text: 'En déposant votre CV vous acceptez que des informations en soient extraites et analysées dans le but de vous proposer des offres d’emploi. Vous acceptez également les {link} de ce site.',
    link: '/conditions',
    textLink: "conditions d'utilisation",
    colorLink: "rgb(174, 0, 255)",
  },
} as CcUploadConfig;

function displayCGU(config: CcUploadConfig): string {
  const link = `<a href="${config.cgu.link}" style="color: ${config.cgu.colorLink}">${config.cgu.textLink}</a>`;
  return config.cgu.text.replace('{link}', link);
}

function uploadFile(form, input) {
  const files = input.files;
  if (files.length > 0) {
      const file = files[0];
      const splits = file.name.split('.');
      const extension = splits[splits.length - 1];
      const docFormats = ["odt", "doc", "docx", "pdf", "txt", "rtf"];
      const imgFormats = ["png", "jpg", "jpeg"];
      const p = form.querySelector('#cc-upload-error');

      if (!docFormats.concat(imgFormats).find(e => e.toLowerCase() === extension.toLowerCase())) {
        p.innerHTML = `Veuillez déposer un CV dans l'un des formats suivants : odt, doc, docx, pdf, txt, rtf, png, jpg, jpeg`;
      } else if (docFormats.find(e => e.toLowerCase() === extension.toLowerCase()) && file.size > 2000000) {
        p.innerHTML = 'La taille de votre document ne doit pas excéder 2Mo.';
      } else if (imgFormats.find(e => e.toLowerCase() === extension.toLowerCase()) && file.size > 10000000) {
        p.innerHTML = 'La taille de votre image ne doit pas excéder 10Mo.';
      } else {
        form.submit();
      }
  }
}

export function uploadResume(container: string|HTMLElement, config: CcUploadConfig = ccUploadDefaultConfig) {
  config = {
    ...ccUploadDefaultConfig,
    displayIcon: typeof config.displayIcon === 'boolean' && config.displayIcon !== ccUploadDefaultConfig.displayIcon ? config.displayIcon : ccUploadDefaultConfig.displayIcon,
    newTab: typeof config.newTab === 'boolean' && config.newTab !== ccUploadDefaultConfig.newTab ? config.newTab : ccUploadDefaultConfig.newTab,
    domain: config.domain ? config.domain : ccUploadDefaultConfig.domain,
    block: {...ccUploadDefaultConfig.block, ...config.block},
    button: {...ccUploadDefaultConfig.button, ...config.button},
    cgu: {...ccUploadDefaultConfig.cgu, ...config.cgu},
    conditions: {...ccUploadDefaultConfig.conditions, ...config.conditions},
    separator: {...ccUploadDefaultConfig.separator, ...config.separator},
    title: {...ccUploadDefaultConfig.title, ...config.title},
  };

  domReady(function() {
    queue.enqueue(async () => {
      if (container) {
        // Styles
        const color = color => `color:${color}`;
        const bgColor = bgColor => `background-color:${bgColor}`;
        const blockStyles = [];

        if (config.block.styled) {
          config.block.color && blockStyles.push(color(config.block.color));
          config.block.bgColor && blockStyles.push(bgColor(config.block.bgColor));
          config.block.border && blockStyles.push(`border:${config.block.border}`);
          config.block.width && blockStyles.push(`max-width: ${config.block.width}`);
        }

        const titleStyles = [];
        config.title.color && titleStyles.push(color(config.title.color));
        const separatorStyles = [];
        config.separator.color && separatorStyles.push(color(config.separator.color));
        const buttonStyles = [];
        config.button.color && buttonStyles.push(color(config.button.color));
        config.button.borderColor && buttonStyles.push(`border-color:${config.button.borderColor}`);
        config.button.bgColor && buttonStyles.push(bgColor(config.button.bgColor));
        const conditionsStyles = [];
        config.conditions.color && conditionsStyles.push(color(config.conditions.color));
        const cguStyles = [];
        config.cgu.color && cguStyles.push(color(config.cgu.color));

        const element = typeof container === 'string' ? document.getElementById(container) : container;

        // Form
        const form = document.createElement('form');
        form.id = `${element?.id}-form`;
        form.method = 'POST';
        form.enctype = 'multipart/form-data';
        form.action = `${config.domain}/api/resume/extract?quietMode=true&amp;redirect=%2Fjobs%2Ftask`;
        form.className = 'cc-upload-form';
        form.target = config.newTab ? 'blank' : '';
        form.style.cssText = `border-radius: 1rem;padding: 10px;${blockStyles.join(';')}`;

        if (config.displayIcon) {
          const xmlns = 'http://www.w3.org/2000/svg';

          // SVG
          const svg = document.createElementNS(xmlns, 'svg');
          svg.setAttributeNS(null, 'class', 'cc-upload-svg');
          svg.setAttributeNS(null, 'viewBox', '0 0 77 63');
          svg.setAttributeNS(null, 'width', '77');
          svg.setAttributeNS(null, 'height', '63');
          svg.setAttributeNS(null, 'style', 'margin: 10px auto; width: 80px; display: block;');

          // G
          const g = document.createElementNS(xmlns, 'g');
          g.setAttributeNS(null, 'class', 'cloud-upload');
          g.setAttributeNS(null, 'stroke', 'none');
          g.setAttributeNS(null, 'stroke-width', '1');
          g.setAttributeNS(null, 'fill', 'none');
          g.setAttributeNS(null, 'fill-rule', 'evenodd');

          // Polygon
          const polygon = document.createElementNS(xmlns,'polygon');
          polygon.setAttributeNS(null, 'class', 'arrow');
          polygon.setAttributeNS(null, 'style', `fill: ${config.button.bgColor}`);
          polygon.setAttributeNS(null, 'points', '51 38.8076694 38.0000755 26 25 38.8076694 27.3111111 40.8000595 36.5555556 31.6923306 36.5555556 63 39.4444444 63 39.4444444 31.6923306 48.6888889 40.8000595');
          g.appendChild(polygon);

          // Path
          const path = document.createElementNS(xmlns, 'path');
          path.setAttributeNS(null, 'class', 'cloud');
          path.setAttributeNS(null, 'd', 'M68.1593338,21.3294118 C66.7334824,14.1236048 60.1739995,8.64705882 52.7593338,8.64705882 C50.1925926,8.64705882 47.9111111,9.22352941 45.9148894,10.3764706 C42.4928163,4.03529412 35.9334824,0 28.5186676,0 C17.39652,0 8.55570467,8.93536947 8.55570467,20.1764706 C8.55570467,20.4647812 8.55570467,21.0411011 8.55570467,21.3294118 C3.42222222,23.6354448 0,28.5353695 0,34.5882353 C0,42.6588235 6.27407407,49 14.2592593,49 L34.2222222,49 L34.2222222,46.1176471 L14.2592593,46.1176471 C7.98518519,46.1176471 2.85185185,40.9294118 2.85185185,34.5882353 C2.85185185,29.1118401 6.5591847,24.4999246 11.6926672,23.3471342 C11.4074074,22.194193 11.4074074,21.3294118 11.4074074,20.1764706 C11.4074074,10.6647812 19.107482,2.88235294 28.5185185,2.88235294 C35.9333333,2.88235294 42.2074074,7.7824283 44.7741486,14.4117647 C47.0556301,12.6823529 49.907482,11.5294118 52.7593338,11.5294118 C59.6037783,11.5294118 65.0222222,16.7176471 65.5925926,23.3471342 C70.4408153,24.5000754 74.1481481,29.1118401 74.1481481,34.5882353 C74.1481481,40.9294118 69.0148148,46.1176471 62.7407407,46.1176471 L42.7777778,46.1176471 L42.7777778,49 L62.7407407,49 C70.7259259,49 77,42.6588235 77,34.5882353 C77,28.5353695 73.2926672,23.3471342 68.1593338,21.3294118 Z');
          path.setAttributeNS(null, 'fill', '#455068');
          g.appendChild(path);

          svg.appendChild(g);
          form.appendChild(svg);
        }

        if (config.title.display) {
          // Strong (Title)
          const title = document.createElement('strong');
          title.className = 'cc-upload-text-strong';
          title.style.cssText = `text-align: center; display: block; ${titleStyles.join(';')}`;
          title.innerHTML = config.title.text;
          form.appendChild(title);
        }

        if (config.separator.display) {
          // P (Separator)
          const separator = document.createElement('p');
          separator.className = 'cc-upload-text-p';
          separator.style.cssText = `text-align: center; ${separatorStyles.join(';')}`;
          separator.innerHTML = config.separator.text;
          form.appendChild(separator);
        }

        // Button
        const button = document.createElement('button');
        button.type = 'button';
        button.className = 'cc-upload-btn';
        button.style.cssText = `margin: 0px auto; display: block; border-style: none; cursor: pointer; padding: 0.5rem 2rem; border-radius: 0.5rem; ${buttonStyles.join(';')}`;
        button.innerHTML = config.button.text;
        form.appendChild(button);

        // Input
        const input = document.createElement('input');
        input.type = 'file';
        input.name = 'resume';
        input.style.cssText = 'display: none';
        form.appendChild(input);

        if (config.conditions.display) {
          // Small (Conditions)
          const pConditions = document.createElement('p');
          pConditions.style.cssText = 'margin:0rem;'
          const conditions = document.createElement('small');
          conditions.className = 'cc-upload-small cc-upload-small-1';
          conditions.style.cssText = `display:block;text-align: center;padding: 5px 10px; ${conditionsStyles.join(';')}`;
          conditions.innerHTML = config.conditions.text;
          pConditions.appendChild(conditions);
          form.appendChild(pConditions);
        }

        if (config.cgu.display) {
          // Small (CGU)
          const pCgu = document.createElement('p');
          pCgu.style.cssText = 'margin:0rem;'
          const cgu = document.createElement('small');
          cgu.className = 'cc-upload-small cc-upload-small-2';
          cgu.style.cssText = `display:block;text-align:center; ${cguStyles.join(';')}`;
          cgu.innerHTML= displayCGU(config);
          pCgu.appendChild(cgu);
          form.appendChild(pCgu);
        }

        // Error paragraph display
        const p = document.createElement('p');
        p.id = 'cc-upload-error';
        p.setAttribute('style', 'font-size: 80%; color: red; text-align: center;');
        form.appendChild(p);

        element.innerHTML = '';
        element.appendChild(form);

        form.ondrop = function (event) {
          event.preventDefault();
          input.files = event.dataTransfer.files;
          uploadFile(form, input);
        };
        form.ondragover = function (event) {
          event.preventDefault();
        };

        button.addEventListener('click', function () {
          input.click();
        });
        input.addEventListener('change', function () {
          uploadFile(form, input);
        });
      }
    });
  });
}
